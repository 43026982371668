// @flow

import { graphql } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Color } from '../constants';
import Button from './Button';
import Text from './Text';
import LinkedButton from './LinkedButton';

// import type { PricingPlan_plan } from '../__generated__/PricingPlan_plan';

type Props = $ReadOnly<{|
  plan: {
    name: string,
    prices: Array<[number, number]> | null,
    color: string,
    description: string,
    features: string[],
    buttonEmail?: boolean,
  }, // PricingPlan_plan,
  color: string,
  featuresPlus: string | void,
|}>;

const PricingPlan = ({ plan, color, featuresPlus }: Props) => {
  const priceParts = plan.prices
    ? (plan.prices[0][1] / 100).toFixed(2).split('.')
    : null;
  return (
    <StyledWrapper>
      {/* <StyledBlock noBorder>
        <Text color="fainterBlue" size={14} bold uppercase>
          Introductory price
        </Text>
      </StyledBlock> */}
      <StyledMainBordered>
        <StyledAreaColor color={color}>
          <StyledBlock invertBorder largePad>
            <Text size={21} uppercase>
              {plan.name}
            </Text>
          </StyledBlock>
          {priceParts ? (
            <StyledPrice>
              <div className="row">
                <span className="med">£</span>
                <span className="big">{priceParts[0]}</span>
                <span className="med">.{priceParts[1]}</span>
              </div>
              <div className="per">each unit per month</div>
            </StyledPrice>
          ) : (
            <StyledPrice>
              <StyledPrice>
                <span className="free">Free</span>
              </StyledPrice>
            </StyledPrice>
          )}
        </StyledAreaColor>
        <StyledBlock>
          {plan.prices ? '30 Day Free Trial' : 'Free Forever'}
        </StyledBlock>
        <StyledBlock>
          <StyledAudience>{plan.description}</StyledAudience>
        </StyledBlock>
        <StyledBlock noBorder>
          {plan.buttonEmail ? (
            <Button
              label="Preview now"
              href="mailto:contact@guesthug.com?subject=GuestHug+new+feature+request"
            />
          ) : (
            <LinkedButton label="Start free trial" link="(app)" />
          )}
        </StyledBlock>
      </StyledMainBordered>
      <StyleBullets>
        <p>
          {featuresPlus
            ? `Everything in ${featuresPlus} plus`
            : `${plan.name} features include`}
          :
        </p>
        <ul>
          {plan.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
      </StyleBullets>
    </StyledWrapper>
  );
};

export default PricingPlan;

export const fragment = graphql`
  fragment PricingPlan_plan on DatoCmsPlan {
    name
    price
    numberListings
  }
`;

const StyledWrapper = styled('div')`
  max-width: 300px;
  flex: 1;
  text-align: center;
`;

const StyledMainBordered = styled('div')`
  border: 1px ${Color.fainterBlue} solid;
`;

const StyledBlock = styled('div')`
  padding: ${p => rem(p.largePad ? 34 : 18)} 0;
  margin: 0 ${rem(18)};
  ${p =>
    !p.noBorder &&
    `border-bottom: 1px ${p.invertBorder ? '#fff' : Color.fainterBlue} solid`};
`;

const StyledPrice = styled('div')`
  height: 160px;
  padding-bottom: 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
  }

  .big {
    font-size: ${rem(60)};
    margin: ${rem(-14)} ${rem(5)};
  }

  .med {
    font-size: ${rem(25)};
  }

  .per {
    margin-top: 10px;
    font-size: ${rem(14)};
  }

  .free {
    font-size: 34px;
  }
`;

const StyledAreaColor = styled('div')`
  background-color: ${p => p.color};
`;

const StyledAudience = styled.div`
  font-size: ${rem(16)};
  min-height: 64px;
`;

const StyleBullets = styled.div`
  p {
  }

  ul {
    text-align: left;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin: 12px 0 12px 32px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -1px;
      left: -32px;
      background-image: url(/img/icons/check.svg);
    }
  }
`;
